import differenceInDays from 'date-fns/differenceInDays'
import capitalize from 'lodash/capitalize'
import { toDateFormat } from 'lib/dateFormat'
import arrayHasData from 'lib/arrayHasData'
import { getAreaSafeName } from 'lib/utils'
import Listing from '@/components/shared/listing'

const TODAY = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date().getDate()
)

const getEventsListingMixpanelProperties = ({
  startDate,
  endDate,
  popularEventsPresent,
  eventsArea,
  userArea,
  listingsType,
  eventsShown,
  filtersSet,
}) => {
  const daysBetweenTodayAndStartDate = differenceInDays(
    toDateFormat(startDate),
    TODAY
  )

  return {
    data: {
      'Different Region': eventsArea?.id !== userArea?.id,
      'Events Region Id': eventsArea?.id?.toString(),
      'Events Region Name': getAreaSafeName(eventsArea, eventsArea?.country),
      'User Region Id': userArea?.id?.toString(),
      'User Region Name': userArea?.name,
      Archive: false,
      'Start Date': startDate,
      'Start Date days from today': daysBetweenTodayAndStartDate,
      'End Date': endDate,
      'Popular Events Present': popularEventsPresent,
      'Events Shown': eventsShown || 0,
      'List Type': listingsType,
      'Filters Set': filtersSet,
    },
  }
}

const getMixpanelProperties = (
  data,
  {
    contentObjectType = '',
    contentObjectId,
    contentObjectName,
    isArchive,
    popularEventsPresent,
    userArea,
  }
) => {
  if (arrayHasData(data)) {
    const daysBetweenTodayAndStartDate = differenceInDays(
      toDateFormat(data[0]?.date),
      TODAY
    )
    const differentRegionProperties = getDifferentRegionProperties(
      data,
      contentObjectType,
      userArea
    )
    return {
      data: {
        ...differentRegionProperties,
        Archive: isArchive,
        'Content Object Type': capitalize(contentObjectType),
        'Content Object Id': contentObjectId,
        'Content Object Name': contentObjectName,
        'Start Date': data[0]?.startTime,
        'Start Date days from today': daysBetweenTodayAndStartDate,
        'Popular Events Present': popularEventsPresent,
        'Events Shown': data.length,
      },
    }
  }
  return {
    data: {
      Archive: isArchive,
      'Content Object Type': capitalize(contentObjectType),
      'Popular Events Present': popularEventsPresent,
    },
  }
}

const getDifferentRegionProperties = (data, contentObjectType, userArea) => {
  const contentObjectAreaId = data?.[0]?.venue?.area?.id
  const contentObjectAreaName = getAreaSafeName(
    data?.[0]?.venue?.area,
    data?.[0]?.venue?.area?.country
  )

  if (
    contentObjectType === Listing.Aggregations.Promoter ||
    contentObjectType === Listing.Aggregations.Club
  ) {
    return {
      'Different Region': contentObjectAreaId !== userArea?.id,
      'Events Region Id': contentObjectAreaId?.toString(),
      'Events Region Name': contentObjectAreaName?.toString(),
      'User Region Id': userArea?.id?.toString(),
      'User Region Name': userArea?.name,
    }
  }

  return {}
}

export { getEventsListingMixpanelProperties }
export default getMixpanelProperties
